import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default/DefaultTemplate.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Protecting your private information is our priority. This Statement of Privacy applies to buildingauthentech.com, and AuthenTech, and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to AuthenTech include buildingauthentech.com and AuthenTech. The AuthenTech website is an architectural design services site. By using the AuthenTech website, you consent to the data practices described in this statement.`}</p>
    <h3>{`Collection of Your Personal Information`}</h3>
    <p>{`In order to better provide you with products and services offered, AuthenTech may collect personally identifiable information, such as your:`}</p>
    <ul>
      <li parentName="ul">{`First and Last Name`}</li>
      <li parentName="ul">{`E-mail Address`}</li>
      <li parentName="ul">{`Phone Number`}</li>
      <li parentName="ul">{`Project location`}</li>
    </ul>
    <p>{`We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) sending us an email message. We will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.`}</p>
    <h3>{`Use of Your Personal Information`}</h3>
    <p>{`AuthenTech collects and uses your personal information to operate and deliver the services you have requested.`}</p>
    <p>{`AuthenTech may also use your personally identifiable information to inform you of other products or services available from AuthenTech and its affiliates.`}</p>
    <h3>{`Sharing Information with Third Parties`}</h3>
    <p>{`AuthenTech does not sell, rent, or lease its customer lists to third parties.`}</p>
    <p>{`AuthenTech may share data with trusted partners to help perform statistical analysis, send you email or postal mail, or provide customer supports. All such third parties are prohibited from using your personal information except to provide these services to AuthenTech, and they are required to maintain the confidentiality of your information.`}</p>
    <p>{`AuthenTech may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on AuthenTech or the site; (b) protect and defend the rights or property of AuthenTech; and/or (c) act under exigent circumstances to protect the personal safety of users of AuthenTech, or the public.`}</p>
    <h3>{`Automatically Collected Information`}</h3>
    <p>{`Information about your computer hardware and software may be automatically collected by AuthenTech. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the AuthenTech website.`}</p>
    <h3>{`Use of Cookies`}</h3>
    <p>{`The AuthenTech website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you.`}</p>
    <p>{`One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize AuthenTech pages, or register with AuthenTech site or services, a cookie helps AuthenTech to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same AuthenTech website, the information you previously provided can be retrieved, so you can easily use the AuthenTech features that you customized.`}</p>
    <p>{`You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the AuthenTech services or websites you visit.`}</p>
    <h3>{`Links`}</h3>
    <p>{`This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.`}</p>
    <h3>{`Right to Deletion`}</h3>
    <p>{`Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:`}</p>
    <ul>
      <li parentName="ul">{`Delete your personal information from our records; and`}</li>
      <li parentName="ul">{`Direct any service providers to delete your personal information from their records.`}</li>
    </ul>
    <p>{`Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:`}</p>
    <ul>
      <li parentName="ul">{`Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;`}</li>
      <li parentName="ul">{`Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;`}</li>
      <li parentName="ul">{`Debug to identify and repair errors that impair existing intended functionality;`}</li>
      <li parentName="ul">{`Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;`}</li>
      <li parentName="ul">{`Comply with the California Electronic Communications Privacy Act;`}</li>
      <li parentName="ul">{`Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;`}</li>
      <li parentName="ul">{`Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;`}</li>
      <li parentName="ul">{`Comply with an existing legal obligation; or`}</li>
      <li parentName="ul">{`Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.`}</li>
    </ul>
    <h3>{`Children Under Thirteen`}</h3>
    <p>{`AuthenTech does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.`}</p>
    <h3>{`Changes to This Statement`}</h3>
    <p>{`AuthenTech reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our website, and/or by updating any privacy information. Your continued use of the website and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.`}</p>
    <h3>{`Contact Information`}</h3>
    <p>{`AuthenTech welcomes your questions or comments regarding this Statement of Privacy. If you believe that AuthenTech has not adhered to this Statement, please contact AuthenTech at:`}</p>
    <Text as="p" className="font-semibold" mdxType="Text">
  AuthenTech
    </Text>
    <Text as="p" className="font-medium" mdxType="Text">
  2510 W Horizon Ridge Pkwy Ste 200
    </Text>
    <Text as="p" className="font-medium" mdxType="Text">
  Henderson, Nevada 89052
    </Text>
    <Text as="p" className="font-normal" className="mt-2" mdxType="Text">
  Email Address:{" "}
  <Link to="mailto:info@buildingauthentech.com" mdxType="Link">
    info@buildingauthentech.com
  </Link>
    </Text>
    <Text as="p" className="font-normal" className="mb-6" mdxType="Text">
  Telephone number: <Link to="tel:650-761-1372" mdxType="Link">(650) 761-1372</Link>
    </Text>
    <Text as="p" className="mb-6" mdxType="Text">
  Effective as of May 22, 2021
    </Text>
    <sup>*</sup>If you need to review our policy in another language, please review
it in Google Translate or a similar platform. AuthenTech is not responsible for
any information lost in translation.

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      